import React, { useContext, Suspense, lazy, memo } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import { AuthContext } from "./auth.context";

import Loading from "../components/Loading";

import LoadingIndicator from "../components/LoadingIndicator"

const Home = lazy(() => import("../pages/HomePage/Home"));

const RecoveryPassword = lazy(() => import("../pages/RecoveryPasswordPage"));

const Login = lazy(() => import("../pages/LoginPage/Login"));

const PrivateRoute = lazy(() => import("../components/Routes/PrivateRoute"));

const PublicRoute = lazy(() => import("../components/Routes/PublicRoute"));

const Routes = () => {
  const { authData, checkAuthentication } = useContext(AuthContext);

  return (
    <HashRouter hashType={"noslash"}>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/home">
            <PrivateRoute
              component={Home}
              isAuthenticated={authData.isAuthenticated}
              checkAuthentication={checkAuthentication}
            />
          </Route>
          <Route path="/recoverypassword">
            <PublicRoute
              component={RecoveryPassword}
              isAuthenticated={authData.isAuthenticated}
              checkAuthentication={checkAuthentication}
            />
          </Route>
          <Route path="/">
            <PublicRoute
              component={Login}
              isAuthenticated={authData.isAuthenticated}
              checkAuthentication={checkAuthentication}
            />
          </Route>
        </Switch>
      </Suspense>
    </HashRouter>
  );
};

export default memo(Routes);
