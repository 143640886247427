import axios from 'axios';

export const updateAllDeviceTags = (externalUserId, tags) => {
  let base_url = process.env.REACT_APP_ONE_SIGNAL_API_URL;
  var _envAppId = process.env.REACT_APP_ONE_SIGNAL_APP_ID;

  return new Promise((resolve, reject) => {
    axios.put(base_url + `/apps/${_envAppId}/users/${externalUserId}`, { tags })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}