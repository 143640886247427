import React, { useState, useContext } from 'react'
import { checkIsAuthenticated, removeAccessToken } from 'src/services/auth.services'
import Routes from './Routes'
import history from 'src/helpers/history'
import { mobileCheck, removeViewRemessaFilters } from 'src/helpers/general'
import { updateAllDeviceTags } from 'src/services/onesignal.services'

import { CompanyContext } from './company.context'

export const AuthContext = React.createContext()

export const AuthContextState = () => {

    const authInitialState = {
        isAuthenticated: false,
        userData: null
    }

    const { dispatch } = useContext(CompanyContext)

    const [authData, setAuthData] = useState(authInitialState)
    const checkAuthentication = () => {
        return new Promise((resolve, reject) => {
            checkIsAuthenticated()
                .then(() => {
                    setAuthData({ ...authData, isAuthenticated: true })

                    if(authData.userData) {
                        dispatch({
                            type: 'CHANGE_ACTUAL_COMPANY',
                            payload: {
                                empresa: authData.userData.empresa[0],
                                index: 0
                            }
                        })
                    }
                    resolve()
                })
                .catch((error) => {
                    setAuthData(authInitialState)
                    reject(error)
                })
        })
    }

    const dispatchLogout = notification => {
        removeAccessToken();
        removeViewRemessaFilters();
        setAuthData(authInitialState);
        if (notification.custom) {
            sessionStorage.setItem('dispatchLogin', JSON.stringify({ notification: notification, logout: false }));
            history.replace('/')
        } else {
            sessionStorage.setItem('dispatchLogin', JSON.stringify({ notification: {}, logout: true }));
            history.replace('/')
        }

        dispatch({
            type: 'RESET',
            payload: {}
        })
    }

    const updateUserTagsOneSignal = (userData) => {
        var OneSignal = window.OneSignal || [];

        var _env = 'DEV';

        if (process.env.REACT_APP_ENV === 'production') {
            _env = 'PRD';
        } else if (process.env.REACT_APP_ENV === 'homologation') {
            _env = 'HML';
        }

        let tags = {};

        OneSignal.push(["getTags", function (_tags) {
            if (Object.keys(_tags).length !== 0) {
                OneSignal.deleteTags(Object.keys(_tags), function (tagsDeleted) {
                    OneSignal.setExternalUserId(userData.email);
                    userData.empresa.forEach((emp) => {
                        OneSignal.sendTag(emp.empresa_id, true);
                        tags[emp.empresa_id] = true;
                    });

                    OneSignal.sendTag("IdUsr", userData.id);
                    tags['IdUsr'] = userData.id;

                    OneSignal.sendTag("IdPerfil", userData.profile);
                    tags['IdPerfil'] = userData.profile;

                    OneSignal.sendTag("Env", _env);
                    tags['Env'] = _env;

                    let difference = tagsDeleted.filter(x => !Object.keys(tags).includes(x));
                    let tagsToDelete = difference.reduce((o, key) => ({ ...o, [key]: "" }), {})

                    setTimeout(() => {
                        updateAllDeviceTags(userData.email, { ...tags, ...tagsToDelete })
                           
                    }, 2000);
                });
            } else {
                OneSignal.setExternalUserId(userData.email);
                userData.empresa.forEach((emp) => {
                    OneSignal.sendTag(emp.empresa_id, true);
                    tags[emp.empresa_id] = true;
                });

                OneSignal.sendTag("IdUsr", userData.id);
                tags['IdUsr'] = userData.id;

                OneSignal.sendTag("IdPerfil", userData.profile);
                tags['IdPerfil'] = userData.profile;

                OneSignal.sendTag("Env", _env);
                tags['Env'] = _env;

                setTimeout(() => {
                    updateAllDeviceTags(userData.email, tags)
                }, 2000);
            }
        }]);
    }

    const dispatchLogin = userData => {
        if (!mobileCheck()) {
            try {
                var OneSignal = window.OneSignal || [];

                OneSignal.push(function () {
                    OneSignal.on('popoverAllowClick', function () {
                        OneSignal.registerForPushNotifications();
                    });

                    OneSignal.on('subscriptionChange', function (isSubscribed) {
                        updateUserTagsOneSignal(userData);
                    });

                    OneSignal.showSlidedownPrompt();
                });

                setTimeout(() => {
                    updateUserTagsOneSignal(userData);
                }, 5000);
            } catch (error) {
                console.log(error);
            }
        }

        dispatch({
            type: 'CHANGE_ACTUAL_COMPANY',
            payload: {
                empresa: userData.empresa[0],
                index: 0
            }
        })

        setAuthData({ isAuthenticated: true, userData });
        
        history.push('/home');
    }

    return (
        <AuthContext.Provider value={{ authData, dispatchLogin, checkAuthentication, dispatchLogout }}>
            <Routes />
        </AuthContext.Provider>
    )
}

export default AuthContextState;