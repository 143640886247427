import dayjs from 'dayjs';
import { checkIsAuthenticated, removeAccessToken } from "../services/auth.services";
import { estados } from './estadosBrasil';
import { formatDate } from './format';
import history from './history';

export const getEstados = () => {
    return estados;
}
export const getCidades = (sigla) => {
    return estados.find(_estado => _estado.sigla === sigla).cidades.map(_cidade => ({ label: _cidade, value: _cidade }))
}

export function validateDate(date) {
    date = formatDate(date);
    return date.match(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/);
}

export function validateCPF(cpf) {
    if (!cpf) return false;

    cpf = cpf.toString().replace(/\D/g, '');
    if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    var result = true;
    [9, 10].forEach(function (j) {
        var soma = 0, r;
        cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
            soma += parseInt(e) * ((j + 2) - (i + 1));
        });
        r = soma % 11;
        r = (r < 2) ? 0 : 11 - r;
        if (r.toString() !== cpf.substring(j, j + 1)) result = false;
    });
    return result;
}

export function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return false

    //|| /^(\d)\1{13}/.test(cnpj)
    if (cnpj.toString().length !== 14) return false;
    console.log(cnpj.slice(9, 13))

    if (cnpj.slice(8, 12) === "0000" || cnpj.slice(9, 13) === "0000") return true

    // Valida DVs
    let tamanho = parseInt(cnpj.toString().length - 2)
    let numeros = cnpj.toString().substring(0, tamanho);
    let digitos = cnpj.toString().substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(0))) {
        return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.toString().substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1)))
        return false;

    return true;
}

export function validarCpfCnpj(val) {
    val = val.replace(/[^\d]+/g, '');
    if (val.toString().length === 11) {
        return validateCPF(val)
    } else {
        return validarCNPJ(val);
    }
}

export function validaContaAgencia(val, tipo, pattern) {
    const regex1 = new RegExp(`^([0-9]{${pattern}})$`);
    const regexInvalid1 = new RegExp(`^([0]{${pattern}})$`);
    const regex2 = new RegExp(`^([0-9]{${pattern}}-([X|x]|[0-9]))$`);
    const regexInvalid2 = new RegExp(`^([0]{${pattern}}-([X|x]|[0-9]))$`);

    try {
        return tipo === 'agencia'
            ? val.length === 6
                ? (val.match(regex2) && !val.match(regexInvalid2))
                : (val.padStart(4, 0).match(regex1) && !val.padStart(4, 0).match(regexInvalid1))
            : val.length === 14
                ? (val.match(regex2) && !val.match(regexInvalid2))
                : (val.padStart(14, 0).match(regex1) && !val.padStart(14, 0).match(regexInvalid1));
    } catch {
        return false;
    }
}

export function mobileCheck() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export function getTextWidth(text, font) {
    var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width;
}

export function checkInactive(save) {
    const lastRequest = localStorage.getItem("LAST_REQUEST");

    if (!!lastRequest && Number.isInteger(parseInt(lastRequest))) {
        const diff = Math.round(((Date.now() - lastRequest) / 1000) / 60) //mili to min
        if (diff >= parseInt(process.env.REACT_APP_INACTIVE_TIMEOUT)) {
            const alert = {
                type: "warning",
                message: "Você foi desconectado por inatividade. Faça login para continuar.",
                confirmButton: "Tudo bem",
                custom: true,
            };

            removeAccessToken();
            removeViewRemessaFilters();
            sessionStorage.setItem('dispatchLogin', JSON.stringify({ notification: alert, logout: false }));
            history.replace('/');

            return true;
        } else {
            if (localStorage.getItem('top_token')) {
                checkIsAuthenticated()
                    .catch((error) => {
                        const alert = {
                            type: "warning",
                            message: "Você foi desconectado por inatividade. Faça login para continuar.",
                            confirmButton: "Tudo bem",
                            custom: true,
                        };

                        removeAccessToken();
                        removeViewRemessaFilters();
                        sessionStorage.setItem('dispatchLogin', JSON.stringify({ notification: alert, logout: false }));
                        history.replace('/');
                    });
            }
        }
    } else {
        if (save) {
            localStorage.setItem("LAST_REQUEST", Date.now());
        }
    }

    return false;
}

export function saveViewRemessaFilters(filters) {
    sessionStorage.setItem('filters', JSON.stringify(filters));
}

export function removeViewRemessaFilters() {
    sessionStorage.removeItem('filters');
}

export function getViewRemessaFilters() {
    return sessionStorage.getItem('filters') ? JSON.parse(sessionStorage.getItem('filters')) : null;
}

export function convertFileSize(fileSize) {
    if (fileSize < 1000000) {
        return `${(fileSize / 1024).toFixed(1)}kb`;
    }

    return `${(fileSize / (1024 * 1024)).toFixed(1)}mb`;
}

export function getMesAno({ reference = dayjs(), next = false, past = false }) {
    if (next) {
        reference = dayjs(reference).add(1, 'month');
    }

    if (past) {
        reference = dayjs(reference).subtract(1, 'month');
    }

    return { label: next ? 'Próximo' : past ? 'Anterior' : ((reference.month() !== dayjs().month()) || (reference.year() !== dayjs().year())) ? `${(reference.month() + 1).toString().padStart(2, '0')}/${reference.year()}` : 'Atual', mes: reference.month() + 1, ano: reference.year() };
}

export function getMeses() {
    return ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
}

export function getValueFromPdfData(pdfText) {
    try {
        const valueIndex = pdfText.toLowerCase().indexOf('valor')
        const value = pdfText.substring(valueIndex + 6)
        let firstNumberOfValueIndex = -1
        for (const v of value) {
            if (Number(v)) {
                firstNumberOfValueIndex = value.indexOf(v)
                break
            }
        }
        const valueNumber = value
            .substring(firstNumberOfValueIndex)
            .split('\n')[0]
            .split(' ')[0]

        let newValue

        if (valueNumber.toString().trim().lastIndexOf('.') < valueNumber.toString().trim().lastIndexOf(',')) {
            newValue = valueNumber.toString().replace(/[^0-9,]*/g, '').replace(',', '.');
        } else {
            newValue = valueNumber.toString().replace(/[^0-9.]*/g, '')
        }

        newValue = ajusteCasasDecimaisSemArredondar(newValue, 2)

        return Number(newValue)
    } catch {
        return 0
    }
}

export const ajusteCasasDecimaisSemArredondar = (nr, casas) => {
    nr = nr.replace(/[,]/g, '.')
    const arrayPonto = nr.split('.')
    if ((arrayPonto.length === 2 && arrayPonto[1].trim() === '') || (arrayPonto.length === 1)) {
        nr = `${arrayPonto[0]}.00`
    } else if (arrayPonto.length > 2) {
        const ultimoElemento = arrayPonto.pop()
        nr = `${arrayPonto.join('').toString()}.${ultimoElemento}`
    }

    const nrTruncado = nr.slice(0, (nr.indexOf(".")) + 1 + casas);
    return nrTruncado
}