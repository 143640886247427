import { TopAPI, TopAPIAuth } from '../config/api';
import getErrorData from '../helpers/getErrorData';
import CryptoJS from 'crypto-js'

export const removeAccessToken = () => {
  localStorage.removeItem('top_token')
  localStorage.removeItem('user_data')
  localStorage.removeItem('top_token_crdntls')
}

//get access token from local storage
export const getAccessToken = () => {
  const accessToken = localStorage.getItem('top_token')
  return !!accessToken ? accessToken : null;
}

//login method
export const authLogin = (credentials) => {

  return new Promise((resolve, reject) => {
    TopAPI().post('/loginCrendetials', {
      username: credentials.email.toLowerCase(),
      password: CryptoJS.AES.encrypt(credentials.password, process.env.REACT_APP_ENCRYPTION_KEY).toString(),
      response: credentials.captcha
    }).then((response) => {
      resolve(response.data)
    })
      .catch((error) => {
        removeAccessToken();
        const errorData = getErrorData(error, false)
        if (errorData.code === 401) {
          reject({
            code: 401,
            email: "E-Mail ou Senha inválidos",
            password: "E-Mail ou Senha inválidos"
          })
        }
        reject(errorData)
      })
  })

}

//check token method (assuming already logged and had bearer on axios instance header)
export const checkIsAuthenticated = () => {
  return new Promise((resolve, reject) => {
    // resolve() //comment it if you don't want to check the token when entering in a route

    TopAPIAuth(false, 15000, false).get('/credent')
      .then((response) => {
        if (!response.data.reset_pass) resolve()
        else {
          removeAccessToken();
          reject({
            confirmButton: 'Tudo bem',
            custom: true,
            open: true,
            type: 'warning',
            message: 'Faça login para continuar.'
          })
        }
      })
      .catch((error) => {
        const errorData = getErrorData(error)
        removeAccessToken()
        reject(errorData)
      })
  })
}
