import dayjs from "dayjs";
export function formatMoney(number = 0, prefix = true, absolute = true) {
    number = Number(number);
    if(typeof number === 'number') {
        number = absolute ? Math.abs(number) : number;
        const convertedNumber = number.toLocaleString('pt-BR', { currency: 'BRL', maximumFractionDigits: 2, minimumFractionDigits: 2 });
        return prefix ? `R$ ${convertedNumber}` : convertedNumber;
    }
    else 
        return "-";
}

export function titleCase(text = "") {
    try {
        return text.toLowerCase().split(' ').map((word) => {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    } catch {
        return 'Sem Nome de Usuário';
    }
}

export function formatValue(number = "") {
    return String(number).toLocaleString('pt-br', { currency: 'BRL' });
}

export function formatCpfCnpj(value = "") {
    value = retirarFormatacao(value);

    if (value.length <= 11) {
        value = mascaraCpf(value);
    } else {
        value = mascaraCnpj(value);
    }

    return value;
}

export function retirarFormatacao(value) {
    return value ? value.toString().replace(/(\.|\/|[-])/g, "") : '';
}

export function mascaraCpf(value = "") {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
}

export function mascaraCnpj(value) {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
}

export function mascaraPhone(value = "") {
    return value.padStart(13, "0").slice(2, value.length).replace(/(\d{2})(\d{4,5})(\d{4})/g, "($1) $2-$3")
}

export function formatDigit(value = "", agType) {
    if (agType) {
        value = value.replace(/(\d{4})(\d{1}|\w{1})/g, "$1-$2");
    } else {
        value = value.replace(/(\d{12})(\d{1}|\w{1})/g, "$1-$2");
    }

    return value;
}

export function formatUserProfile(numberType) {
    switch (numberType) {
        case 0:
            return "Administrador Top";
        case 1:
            return "Administrador Multipag";
        case 2:
            return "Operador Multipag"
        case 3:
            return "Administrador Conveniada"
        case 4:
            return "Operador Conveniada"
        case 5:
            return "Administrador Lojista"
        case 6:
            return "Operador Lojista"
        default:
            return "Sem Perfil"
    }
}

export function formatCep(cep = "") {
    return cep.replace(/(\d{5})(\d{3})/g, "$1-$2")
}

export function delSpaces(text = "") {
    return text.trim().split(' ').join(' ');
}

export function formatDate(dateString = "", reg = '/', en = false) {
    switch (reg) {
        case "-":
            return dateString.replace(/(\d{4})-(\d{2})-(\d{2})/g, "$3/$2/$1");

        case "iso":
            dateString = dayjs(dateString);
            let dateFormated = !en ? dateString.format('DD/MM/YYYY HH:mm:ss') : dateString.format('YYYY-MM-DD');
            return dateFormated;

        default:
            dateString = dateString.replaceAll(reg, '');
            return dateString.replace(/(\d{2})(\d{2})(\d{4})/g, "$1/$2/$3");
    }
}

export function formatBankAccount(value) {
    if(!!value){
        value = String(value);
        let digit = value.slice(-1);
        value = value.padStart(8, "0").slice(0, -1);
        return`${value}-${digit}`
    }
    else
        return ""
}