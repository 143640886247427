import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import * as Sentry from "@sentry/browser";
import React from "react";
import history from "../../helpers/history";

const useStyles = makeStyles((theme) => ({
  dialog: {
    color: "#787878 !important",
    textAlign: "center",
    borderRadius: "5px",
    minHeight: "360px",
    minWidth: "272px",
    // maxHeight: "360px",
    maxWidth: "272px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 0",
  },
  dialogTitle: {
    padding: 0,
    minHeight: "95px",
    marginBottom: "8px",
    justifyContent: "center",
    "& > h2": {
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      "& > svg": {
        marginBottom: "8px",
      },
      "& > span": {
        fontWeight: "bold",
        fontSize: "24px",
        color: "#000",
      },
    },
  },
  dialogContent: {
    maxWidth: "70%",
    margin: "auto",
    marginBottom: theme.spacing(4),
    padding: 0,
    "& > p": {
      fontSize: "14px",
    },
  },
  dialogAction: {
    display: "block",
    padding: 0,
    border: "none",
    "&> :not(:first-child)": {
      margin: 0,
    },
    "& > button": {
      maxWidth: "200px",
      margin: "auto auto .5rem",
    },
  },
  maxWidth: {
    maxWidth: "360px",
    padding: "0px !important",
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
}));

export default function Alert(props) {
  const classes = useStyles();

  const types = {
    error: {
      title: "Erro",
      icon: ErrorIcon,
      color: "#F22020",
      colorButton: "#FFF",
    },
    warning: {
      title: "Atenção",
      icon: ErrorIcon,
      color: "#FFD538",
      colorButton: "#000",
    },
    success: {
      title: "Tudo Top",
      icon: CheckCircleIcon,
      color: "#27AE60",
      colorButton: "#FFF",
    },
    delete: {
      title: props.deactivate ? "Deseja Desativar?" : "Deseja Excluir?",
      icon: ErrorIcon,
      color: "#F22020",
      colorButton: "#FFF",
    },
    deleteWarning: {
      title: "Deseja Excluir?",
      icon: ErrorIcon,
      color: "#FFD538",
      colorButton: "#000",
    },
  };

  const Icon = !!props.alert.type ? types[props.alert.type].icon : "";

  const handleClose = props.customHandleClose
    ? props.customHandleClose
    : props.alert.customHandleClose
    ? props.alert.customHandleClose
    : () => {
        props.setAlert({ ...props.alert, open: false });
      };

  const sendSentryError = (error, errorInfo) => {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
    //TODO: Verificar a necessidade desse tratamento no modal de alerta
    localStorage.removeItem("top_token");
    history.push("/");
    !!props.alert.confirmButtonCallback
      ? props.alert.confirmButtonCallback()
      : handleClose();
  };

  return (
    <Dialog
      maxWidth={false}
      open={props.alert.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.dialog }}
      backdropClick={true}
      disableEscapeKeyDown={true}
    >
      <Container
        component="main"
        maxWidth="xs"
        classes={{ maxWidthXs: classes.maxWidth }}
      >
        {!!props.alert.type ? (
          <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
            <Icon
              style={{
                fontSize: "53.33px",
                color: types[props.alert.type].color,
              }}
            />
            <span>{types[props.alert.type].title}</span>
          </DialogTitle>
        ) : (
          ""
        )}
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText id="alert-dialog-description">
            {props.alert.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogAction }}>
          {!!props.alert.confirmButton ? (
            <Button
              onClick={
                !!props.alert.confirmButtonCallback
                  ? props.alert.type === "error"
                    ? () => {
                        sendSentryError(
                          props.alert.error,
                          props.alert.erroInfo
                        );
                      }
                    : props.alert.confirmButtonCallback
                  : handleClose
              }
              style={{
                background: types[props.alert.type].color,
                color: types[props.alert.type].colorButton,
              }}
            >
              {props.alert.confirmButton}
            </Button>
          ) : null}
          {!!props.alert.cancelButton ? (
            <Button
              variant="outlined"
              onClick={
                !!props.alert.cancelCallback
                  ? props.alert.cancelCallback
                  : handleClose
              }
              color="inherit"
            >
              {props.alert.cancelButton}
            </Button>
          ) : null}
        </DialogActions>
      </Container>
    </Dialog>
  );
}
