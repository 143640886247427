import { removeAccessToken } from "../services/auth.services";
import { removeViewRemessaFilters } from "./general";
import history from "./history";

const getErrorData = (error, unauthorizedLogout = true, replaceMessage = null) => {
    error = {
        ...error,
        type: 'error',
        confirmButton: 'Tudo bem',
        custom: true,
        open: true
    };

    if (error.message && error.message.includes("ABORTED_REQUEST")) {
        error.code = -2
    }
    else {
        if (!error.response) {

            if(error.codigo == 422) {
                error.type = 'warning'
                error.message = error.message
            }
            else {
                //any axios error, not able to reach the server (could be network error or timeout or whatever)
                error.message = 'Tivemos um problema ao realizar essa requisição, tente novamente.'
                error.code = -1
            }
        } else {
            // http status code
            const code = error.response.status;
            switch (code) {
                case 500:
                    //server error
                    error.message = !replaceMessage ? 'Ocorreu um erro interno. Se acontecer novamente, entre em contato conosco.' : replaceMessage;
                    break;

                case 401:
                    // unauthorized
                    error.type = 'warning'
                    error.message = 'Faça login para continuar.'

                    const alert = {
                        type: "warning",
                        message: "Você não tem acesso a essa funcionalidade. Faça login para continuar.",
                        confirmButton: "Tudo bem",
                        custom: true,
                    };

                    error.message = !replaceMessage ? error.message : replaceMessage;

                    if (unauthorizedLogout) {
                        removeAccessToken();
                        removeViewRemessaFilters();
                        sessionStorage.setItem('dispatchLogin', JSON.stringify({ notification: alert, logout: false }));
                        history.replace('/');
                    }
                    break;

                case 422:
                    //invalid fields
                    error.type = 'warning'
                    error.data = error.response.data
                    if (typeof error.data === "string") {
                        error.message = error.data.includes('Error') ? error.data.split('Error: ')[1] : error.data;
                    } else {
                        error.message = error.response.data.errorMessage || "Detectamos erros durante o processamento da sua requisição, verifique se enviou os dados corretamente."
                    }
                    break;

                case 400:
                    //bad request
                    if (error.response.data.errorId === 'DEPOSITO_HORARIO_LIMITE' || error.response.data.errorId === 'SEM_DIA_COBRANCA') {
                        error.type = 'warning';
                        error.data = error.response.data;
                        error.message = error.response.data.errorMessage;
                    } else {
                        error.message = 'Estamos com problemas técnicos, tente novamente.'
                    }
                    break;

                case 404:
                    //not found
                    error.type = 'warning';
                    error.message = error.response.data.message || error.response.data.errorMessage;
                    break;

                default:
                    error.message = error.response.data.errorMessage;
                    break;
            }

            error.code = code;
        }
    }


    return error;
}

export default getErrorData;