import React from 'react';
import history from '../../helpers/history';
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button } from '@material-ui/core';

import ilus from '../../assets/images/ilustracao.svg';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    paddingLeft: '112px',
    paddingRight: '112px'
  },
  title: {
    fontSize: '96px',
    color: '#01001A',
    fontWeight: '300'
  },
  subTitle: {
    fontSize: '34px',
    color: '#01001A'
  },
  description: {
    fontSize: '16px',
    color: '#686868',
  },
  button: {
    height: "100%",
    fontSize: "16px",
    padding: "16px",
    color: "#ffffff",
    backgroundColor: "#FF6104",
    borderRadius: "5px",
    marginTop: "48px",
    '&:hover': {
      backgroundColor: "#CC4C00"
    }
  }
}));

export default function ErrorPage(props) {
  const classes = useStyles();

  const tryAgain = () => {
    history.push('/');
    window.location.reload();
  }

  return (
    <Box className={classes.container}>
      <Box>
        <Box>
          <Typography variant='h1' className={classes.title}>Ops,</Typography>
          <Typography variant='h2' className={classes.subTitle}>alguma coisa não foi Top.</Typography>
        </Box>

        <Box mt="16px">
          <Typography variant='body1' className={classes.description}>Lamentamos o ocorrido, não sabemos dizer o que houve.</Typography>
          <Typography variant='body1' className={classes.description}>Clique no botão abaixo para tentar novamente.</Typography>
        </Box>

        <Box width="237px" height="56px">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={tryAgain}
          >
            Tentar Novamente
          </Button>
        </Box>
      </Box>

      <img src={ilus} alt="Ilustração" />
    </Box>
  );
}