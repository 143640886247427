import React, { createContext, useReducer, useContext } from 'react'

import { AuthContext } from './auth.context'

export const CompanyContext = createContext()

function CompanyProvider({ children }) {
  const userData = JSON.parse(localStorage.getItem("user_data"));

  const initialState = {
    empresa: userData?.empresa[0] || null,
    index: 0
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_ACTUAL_COMPANY":
        console.log("CHANGE_ACTUAL_COMPANY", action.payload);
        return {
          ...state,
          ...action.payload
        }
      case "RESET":
        return initialState
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CompanyContext.Provider value={{ state, dispatch }}>
      {children}
    </CompanyContext.Provider>
  )
}

export default CompanyProvider;