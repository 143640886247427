import React from 'react';
import ReactDOM from 'react-dom';
import App from './main/App';
import ErrorBoundary from './components/ErrorBoundary';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import * as serviceWorker from './serviceWorker';

import ErrorPage from './pages/ErrorPage/ErrorPage';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import CompanyProvider from 'src/main/company.context'
import AlertProvider from 'src/main/alert.error.context'

// Sentry initialization
Sentry.init({ 
  dsn: process.env.REACT_APP_SENTRY_API, 
  environment: process.env.REACT_APP_ENV, 
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

// OneSignal initialization
var OneSignal = window.OneSignal || [];

// Dev app id's
var _envAppId = process.env.REACT_APP_ONE_SIGNAL_APP_ID;
var _envSafariAppId = process.env.REACT_APP_ONE_SIGNAL_SAFARI_APP_ID;

OneSignal.push(function () {
  if (document.location.hostname !== 'localhost') {
    OneSignal.init({
      safari_web_id: _envSafariAppId,
      appId: _envAppId,
      notifyButton: {
        enable: false,
      },
      welcomeNotification: {
        message: 'Obrigado por se inscrever!'
      },
      subdomainName: "dev-topmultipa",
      persistNotification: false,
      promptOptions: {
        actionMessage: 'Top Multipag quer lhe enviar notificações.',
        exampleNotificationTitleDesktop: 'Esta é uma notificação de exemplo.',
        exampleNotificationMessageDesktop: 'Notificações aparecerão em sua área de trabalho.',
        exampleNotificationTitleMobile: 'Esta é uma notificação de exemplo.',
        exampleNotificationMessageMobile: 'Notificações aparecerão em seu dispositivo.',
        exampleNotificationCaption: 'Você pode se desinscrever a qualquer momento.',
        acceptButtonText: 'Aceitar',
        cancelButtonText: 'Recusar',
        showCredit: false
      }
    });
  }

});

Number.isInteger = Number.isInteger || function (value) {
  return typeof value === "number" &&
    isFinite(value) &&
    Math.floor(value) === value;
};

try {
  ReactDOM.render(
    (
      <ErrorBoundary>
        <ToastContainer hideProgressBar={true} />
        <CompanyProvider>
          <AlertProvider>
            <App />
          </AlertProvider>
        </CompanyProvider>

      </ErrorBoundary>
    ),
    document.getElementById('root')
  );
} catch (error) {
  ReactDOM.render(
    <ErrorPage />,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
