import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import "../index.css";
// import ReactGA from 'react-ga';
import AuthContextState from "./auth.context";

const App = () => {
  const THEME = createTheme({
    typography: {
      button: {
        textTransform: "none",
        height: "50px",
        letterSpacing: "0.5px",
        fontWeight: "bold",
      },
    },
    palette: {
      primary: {
        light: "#FF8238",
        main: "#FF6104",
        gray: "#787878",
        anotherGray: "#ccc",
      },
      secondary: {
        main: "#000",
        light: "#fff",
      },
      dialogTextButton: {
        main: "#fff",
        light: "#fff",
      },
      white: {
        main: "#fff",
        light: "#fff",
      },
      grey: {
        light: "rgba(128, 128, 128, 0.8)",
        main: "rgba(128, 128, 128, 1)",
      },
    },
    
    shape: {
      borderRadius: "5px",
    },
    overrides: {
      MUIDataTable: {
        responsiveScrollMaxHeight: {
          borderRadius: "5px",
        },
      },
      MUIDataTableHeadCell: {
        root: {
          color: "#787878",
          fontWeight: "bold",
        },
        sortAction: {
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        },
        data: {
          color: "#787878",
          fontSize: 14,
        },
        sortActive: {
          fontWeight: "bold",
          color: "#787878",
        },
      },
      MuiTableFooter: {
        root: {
          display: "none",
        },
      },
      MuiTableRow: {
        root: {
          cursor: "pointer",
          height: "40px",
          "&:nth-child(2n)": {
            backgroundColor: "#f3f3f3",
          },
        },
      },
      MuiButton: {
        root: {
          height: "40px !important",
          padding: "8px 16px",
          "&$disabled": {
            backgroundColor: "#ffdfcd !important",
            color: "#fff !important",
          },
          "&.disable-hover:hover": {
            backgroundColor: "transparent",
          },
        },
        outlined: {
          border: "1px solid #CCCCCC !important",
          "&$disabled": {
            backgroundColor: "transparent !important",
            color: "rgb(128,128,128) !important",
            opacity: 0.4,
          },
        },
        colorInherit: {
          color: "#808080",
          "&$disabled": {
            backgroundColor: "transparent !important",
            color: "rgba(0, 0, 0, 0.26) !important",
          },
        },
      },
      MuiTableSortLabel: {
        root: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          opacity: 1,
          "&:not(.MuiTableSortLabel-active)": {
            display: "none",
          },
        },
        active: {
          color: "#787878 !important",
        },
        icon: {
          color: "#787878 !important",
        },
      },
      MuiFormHelperText: {
        root: {
          minHeight: "19px",
          marginTop: 0,
        },
      },
      MuiInputBase: {
        root: {
          "&.Mui-error": {
            border: "1px solid #F22020",
          },
        },
        input: {
          fontSize: "14px",
        },
      },
      MUIDataTableToolbar: {
        root: {
          padding: 0,
        },
        actions: {
          flex: "0",
          display: "flex",
          textAlign: "center",
        },
        icon: {
          flex: "1 !important",
        },
        // left: {
        //   display: "none",
        // },
      },
      MuiTableCell: {
        root: {
          padding: 0,
          paddingLeft: "10px",
          fontSize: "14px",
        },
        body: {
          fontSize: "12px !important",
          wordBreak: "break-all",
        },
      },
      MuiDialogTitle: {
        root: {
          padding: "16px 24px 12px",
          display: "flex",
          maxHeight: "56px",
        },
      },
      MuiAlert: {
        root: {
          width: "100%",
        },
        message: {
          color: "#000000",
        },
        standardSuccess: {
          backgroundColor: "rgba(39, 174, 96, 0.1)",
          "& .MuiAlert-icon": {
            color: "#27AE60",
          },
        },
      },
      MuiAppBar: {
        root: {
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important",
        },
      },
      MuiTabs: {
        root: {
          backgroundColor: "#FF8238",
        },
      },
      MuiToolbar: {
        regular: {
          minHeight: "48px !important",
        },
      },
      MuiDialogActions: {
        root: {
          padding: "24px",
          border: "1px solid #F3F3F3",
        },
      },
      // .MuiToggleButtonGroup-grouped:not(:first-child)
      MuiToggleButton: {
        root: {
          fontWeight: "normal",
          height: "40px",
          border: "1px solid #DEDEDE",
          backgroundColor: "#FAFAFA",
          color: "#787878",
        },
      },
      MuiToggleButtonGroup: {
        grouped: {
          "&:not(:first-child)": {
            borderLeftColor: "#FF6104 !important",
          },
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: "16px",
        },
      },
      MuiFormControlLabel: {
        root: {
          "& .MuiSwitch-root ~ .MuiTypography-root": {
            color: "#808080",
            fontSize: "14px",
            paddingLeft: "6px",
          },
        },
      },
      MuiList: {
        padding: {
          paddingTop: 0,
        },
      },
      MuiListItem: {
        root: {
          color: "#808080",
        },
      },
      MuiTypography: {
        body1: {
          "&.custom": {
            color: "#808080",
            fontSize: "12px",
          },
        },
        body2: {
          "&.custom": {
            color: "#808080",
          },
        },
        h6: {
          "&.custom": {
            color: "#808080",
            fontWeight: "bold",
          },
        },
        subtitle2: {
          "&.custom": {
            color: "#808080",
            fontSize: "9px",
            lineHeight: "9px",
          },
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: "#F3F3F3",
        },
      },
      MuiCard: {
        root: {
          "&.smoke": {
            boxShadow: "1.6px 9.9px 30px rgba(0, 0, 0, 0.1)",
          },
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: "34px",
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={THEME}>
      <AuthContextState />
    </MuiThemeProvider>
  );
};

export default App;
