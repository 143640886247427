/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, forwardRef, useImperativeHandle } from 'react';

import styled from 'styled-components';

import {
  Backdrop,
  CircularProgress,
  Typography
} from '@material-ui/core';

export const Container = styled.div`
  width: 250px;
  height: 250px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
`;


function LoadingIndicator(props, ref) {
  const [open, setOpen] = useState(false);

  const handleLoading = useCallback((status) => {
    setOpen(status)
  }, [])

  useImperativeHandle(ref, () => {
    return {
      handleLoading
    }
  })
  return (
    <Backdrop open={open} style={{ zIndex: 200 }} onClick={handleLoading}>

      <Container>
        <CircularProgress color="primary" />
        <Typography style={{marginTop: 20}}>Carregando...</Typography>
      </Container>
      
    </Backdrop>
  );
}

export default forwardRef(LoadingIndicator);