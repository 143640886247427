import React, { createContext, useReducer } from 'react'

export const AlertContext = createContext()

function AlertProvider({ children }) {

  const initialState = {
    open: false,
    message: "",
    type: "",
    confirmButton: null,
    cancelButton: null,
    confirmButtonCallback: null,
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_ALERT_STATE":
        return {
          ...state,
          ...action.payload
        }
      case "RESET":
        return initialState
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AlertContext.Provider value={{ alertState: state, dispatchAlert: dispatch }}>
      {children}
    </AlertContext.Provider>
  )
}

export default AlertProvider;