import React, { Component } from 'react';
import Alert from '../Alert';
import history from '../../helpers/history';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
    this.backToLogin = this.backToLogin.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  backToLogin() {
    localStorage.removeItem('top_token');
    history.push('/');
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null
    });
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      var message = "Ocorreu um erro interno, nosso suporte técnico está trabalhando nele. Tente novamente mais tarde.";
      var errorType = "error";

      if (this.state.error?.toString().toLowerCase().includes('razaosocial')) {
        message = "Sem empresa cadastrada para o usuário.";
        errorType = "warning";
      }

      return (
        <>
          <ErrorPage />
          <Alert
            alert={{
              message: message,
              type: errorType,
              error: this.state.error,
              errorInfo: this.state.errorInfo,
              confirmButton: "Tudo bem",
              open: this.state.hasError,
              confirmButtonCallback: this.backToLogin
            }}
            customHandleClose={this.backToLogin}
          />
        </>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;