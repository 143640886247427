import axios from "axios";
import { checkInactive } from "../../helpers/general";

// for usual requests
const TopAPI = (dev = false, timeout = 60000) => {
  const xApiKey = process.env.REACT_APP_X_API_KEY;

  const api = axios.create({
    baseURL: !dev
      ? `${process.env.REACT_APP_API_URL}`
      : process.env.REACT_APP_API_URL_MOCK,
    timeout: timeout,
  });

  api.defaults.headers.common["X-Api-Key"] = xApiKey;

  api.interceptors.request.use(function (config) {
    // Do something before request is sent
    localStorage.setItem("LAST_REQUEST", Date.now());

    return config;
  });

  return api;
};

// for authenticated requests
const TopAPIAuth = (
  dev = false,
  timeout = 30000,
  check = true,
  useTokenCredentials = false
) => {
  const token = localStorage.getItem(
    useTokenCredentials ? "top_token_crdntls" : "top_token"
  );
  const xApiKey = process.env.REACT_APP_X_API_KEY;

  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: timeout,
  });

  api.defaults.headers.common["Authorization"] = useTokenCredentials
    ? token
    : `Bearer ${token}`;
  api.defaults.headers.common["X-Api-Key"] = xApiKey;

  api.interceptors.request.use(function (config) {
    //Do something before request is sent
    if (check) {
      if (config.url === "/user" && config.method === "get") {
        checkInactive(false);
      } else {
        checkInactive(true);
      }
    }

    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }

      return Promise.reject(error);
    }
  );

  return api;
};

const TopAPIAWS = (bearer = true, timeout = 30000, check = true) => {
  const token = localStorage.getItem("top_token_crdntls");
  const xApiKey = process.env.REACT_APP_X_API_KEY_AWS;

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL_AWS,
    timeout: timeout,
  });

  if (bearer)
    api.defaults.headers.common[
      process.env.TOKENCREDENTIAL_KEY_NAME || "tokencredentials"
    ] = token;

  api.defaults.headers.common["X-Api-Key"] = xApiKey;
  // api.defaults.headers.common['Access-Control-Allow-Origin'] = "'*'";
  // api.defaults.headers.common['Access-Control-Allow-Methods'] = "'*'";
  // api.defaults.headers.common['Access-Control-Allow-Headers'] = "'*'";

  api.interceptors.request.use(function (config) {


    return config;
  });

  return api;
};

const TopAPIAWS_2 = (bearer = true, timeout = 30000, check = true) => {
  const token = localStorage.getItem("top_token_crdntls");
  const xApiKey = process.env.REACT_APP_X_API_KEY_AWS;

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL_AWS_CENTRAL_NOTIFICACAO,
    timeout: timeout,
  });

  if (bearer)
    api.defaults.headers.common[
      process.env.TOKENCREDENTIAL_KEY_NAME || "tokencredentials"
    ] = token;

  api.defaults.headers.common["X-Api-Key"] = xApiKey;
  // api.defaults.headers.common['Access-Control-Allow-Origin'] = "'*'";
  // api.defaults.headers.common['Access-Control-Allow-Methods'] = "'*'";
  // api.defaults.headers.common['Access-Control-Allow-Headers'] = "'*'";

  api.interceptors.request.use(function (config) {


    return config;
  });

  return api;
};

const CelcoinAPI = async () => {
  try {
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfaWQiOiI0MWI0NGFiOWE1NjQ0MC50ZXN0ZS5jZWxjb2luYXBpLnY1IiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6InRlc3RlIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy91c2VyZGF0YSI6ImExZjRmYmI0ODg3MjRjN2Y5Mzc1IiwiZXhwIjoxNjIyNDczODQyLCJpc3MiOiJDZWxjb2luQVBJIiwiYXVkIjoiQ2VsY29pbkFQSSJ9.jwmIvSZ6MtssMCV7ax0thg65qC0FvvKsZ9JDWWNmcG4";

    // const token = await axios.post(process.env.REACT_APP_CELCOIN_LOGIN,
    //   qs.stringify({
    //     client_id: process.env.REACT_CELCOIN_CLIENTID,
    //     grant_type: process.env.REACT_CELCOIN_GRANT_TYPE,
    //     client_id: process.env.REACT_CELCOIN_CLIENT_SECRET,
    //   }),
    //   {
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded"
    //     }
    //   }
    // )

    const api = axios.create({
      baseURL: process.env.REACT_APP_CELCOIN_PIX,
    });

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api;
  } catch (err) {
    throw new Error("Erro de conexão com a celcoin");
  }
};

export { TopAPI, TopAPIAuth, TopAPIAWS, CelcoinAPI, TopAPIAWS_2 };
